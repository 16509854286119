<template>
    <router-view></router-view>
    <consent-form></consent-form>
<!--    <button class="position-fixed top-0 end-0 w-25 z-20000" @click="closeSocket">Close Socket</button>-->
<!--    <button class="position-fixed top-0 start-0 w-25 z-20000" @click="openSocket">Open Socket</button>-->
</template>

<script lang='ts' setup>

import {onBeforeUnmount, onMounted, ref, watch, provide} from "vue";
import {useAuthStore} from "@/stores/auth-store";
import {useLayoutStore} from "@/stores/layout-store";
import {useRouter} from "vue-router";
import {socket} from "./socket";
import {useToastManager} from "./composables/useToastManager";
import {TYPE} from "vue-toastification";
import apiClient from "@/utils/axios";
import ConsentForm from "./components/ConsentForm.vue";
import {useMessageStore} from "@/stores/message-store";

const router = useRouter();
const authStore = useAuthStore();
const layoutStore = useLayoutStore();
const general_notification = ref<string | null>();

router.afterEach((to, from) => {
    apiClient.get("variable/fetchGeneralNotification")
        .then((response: { data: { general_notification_message: string | null } }) => {
            general_notification.value = response.data.general_notification_message;
            const local_message = localStorage.getItem("general_notification_message");
            if (general_notification.value && local_message !== general_notification.value) {
                localStorage.setItem("general_notification_message", general_notification.value);
                useToastManager().display(general_notification.value, "warning" as TYPE, false);
            } else {
                if (!general_notification.value) {
                    localStorage.removeItem("general_notification_message");
                }
            }
        })
        .catch(() => {
            general_notification.value = null;
        });
});

const messageStore = useMessageStore();

const closeSocket = () => {
    socket.disconnect();
}

const openSocket = () => {
    socket.connect();
}

const hasSocketInitialized = ref<boolean>(false);
const initSocket = () => {
    if (hasSocketInitialized.value) {
        return;
    }
    hasSocketInitialized.value = true;

    if (authStore.user) {
        socket.connect();

        socket.on("message_received:user_" + authStore.user.id + "_message", (...args) => {
            if (args.length > 0) {
                let item = args[0].content;
                let cid = args[0].cid;
                messageStore.addMessage(cid, item);
				messageStore.lastContactId = cid;
            }
        });

        socket.on("message_update:user_" + authStore.user.id + "_message", (...args) => {
            if (args.length > 0) {
                let item = args[0].content;
                let cid = args[0].cid;

                messageStore.updateMessage(cid, item);
            }
        });

        socket.on("connect", () => {
            if (authStore.user) {
                const type = authStore.role && ["manager", "admin"].includes(authStore.role) ? "manager" : "user";
                socket.emit("userConnected", [authStore.user.id, type]);

                let lastSocketId = localStorage.getItem("lastSocketId") || "";
                if (lastSocketId != "") {
                    socket.emit("loadCachedEvents", [lastSocketId, authStore.user.id, type]);
                }

                localStorage.setItem("lastSocketId", socket.id);
            }
        })
    }
}

onMounted(() => {
    window.addEventListener('resize', () => {
        layoutStore.updateBreakpoint()
    });
    layoutStore.updateBreakpoint();

    if (Notification.permission !== "denied") {
        Notification.requestPermission();
    }

    if (authStore.user) {
        initSocket();
    } else {
        watch(() => authStore.user, () => {
            initSocket();
        })
    }

    watch(
        () => authStore.user?.id,
        (newUserId, oldUserId) => {
            if (newUserId) {
                socket.on("subscription_updated:member_" + newUserId, (...args) => {
                    const [event] = args;
                    if (event.type == 'SubscriptionUpdated') {
                        let subscription = event.subscription;
                        if (authStore.user) {
                            authStore.user = {...authStore.user, subscription: subscription};
                        }
                    }
                });
            }

            if (oldUserId) {
                socket.off("subscription_updated:member_" + oldUserId);
            }
        },
        {immediate: true}
    );
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', () => layoutStore.updateBreakpoint());
    if (authStore.user) {
        socket.off("message_received:user_" + authStore.user.id + "_message");
        socket.off("message_update:user_" + authStore.user.id + "_message");
    }
});

</script>

<style lang="scss" scoped>
.z-20000 {
    z-index: 10000;
}
</style>
