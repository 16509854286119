import {reactive} from "vue";
import {io} from "socket.io-client";

export const state = reactive({
    connected: false,
});

export const socket = io(`${import.meta.env.VITE_APP_URL}:3000/`, {
    transports: ["websocket", "polling"], // Keep polling as a fallback
    withCredentials: true,
    secure: true,
    timeout: 45000,
    reconnection: true, // Enable automatic reconnection
    reconnectionAttempts: Infinity, // Retry indefinitely
    reconnectionDelay: 2000, // Wait 2 seconds before trying to reconnect
    reconnectionDelayMax: 10000, // Max delay of 10 seconds
    autoConnect: false,
});

socket.on("connect", () => {
    state.connected = true;
});

socket.on("disconnect", (reason) => {
    state.connected = false;
});