import { User } from '@/utils/types';
import { defineStore } from 'pinia';
import apiClient from '@/utils/axios';
import { useHttpErrorManager } from '@/composables/useHttpErrorManager';
import router from '@/router';
import { useAppHelpers } from '@/composables/useAppHelpers';
import { useLayoutStore } from "@/stores/layout-store";
import { socket } from "@/socket";

export const useAuthStore = defineStore('authStore', {
    state() {
        return {
            user: null as null | User,
            scopes: [] as Array<string>,
            role: null as null | string,
            managerPermission: [] as Array<string>
        };
    },
    getters: {
        isLoggedIn(): boolean {
            if (this.user == null) {
                return false;
            }
            return true;
        },
        getUser(): User | null {
            return this.user;
        }
    },
    actions: {
        updateUser (value : User) {
            if(this.user){
                this.user = value;
            }
        },
        updateHasNewMessage (value : number) {
            if(this.user){
                this.user.has_new_message = value;
            }
        },
        async loadUser(force = false) {
            try {
                const response = await apiClient.get('auth/user');

                this.user = response.data.user;
                this.scopes = response.data.scopes;
                this.role = response.data.user.type;
                this.managerPermission = response.data.managerPermissions;
                if (this.isLoggedIn) {
                    const responseSettings = await apiClient.get('user_setting/fetchTemplateSettings');

                    const colorMode = responseSettings.data.dark_mode;
                    const layoutMode = responseSettings.data.detach_layout;
                    const sidebarSize = responseSettings.data.sidebar_size;

                    useLayoutStore().updateColorMode(colorMode === 1 ? 'dark' : 'light');
                    useLayoutStore().updateLayoutMode(layoutMode === 1 ? 'detached' : 'default');
                    useLayoutStore().updateSidebarMode(sidebarSize === 1 ? 'sm-hover-active' : 'sm-hover');
                }
                return true;
            }
            catch (error) {
                let errorObj = await useHttpErrorManager().handleError(error, false);
                if (this.isLoggedIn) {
                    await this.processLogout();
                }
                return false;
            }
        },
        async onlyLoadUser() {
            try {
                const response = await apiClient.get('auth/user');

                this.user = response.data.user;
                this.scopes = response.data.scopes;
                this.role = response.data.user.type;

                return true;
            }
            catch (error) {
                let errorObj = await useHttpErrorManager().handleError(error, false);
                if (this.isLoggedIn) {
                    await this.processLogout();
                }
                return false;
            }
        },
        async onlyLoadEmployee() {
            try {
                const response = await apiClient.get('auth/user');

                this.user = response.data.user;
                this.scopes = response.data.scopes;
                this.managerPermission = response.data.managerPermissions

                this.role = response.data.user.type;

                return true;
            }
            catch (error) {
                let errorObj = await useHttpErrorManager().handleError(error, false);
                if (this.isLoggedIn) {
                    await this.processLogout();
                }
                return false;
            }
        },
        async processLogout() {
            if(this.user){
                let type = this.role == 'manager' || this.role == 'admin' ? 'manager' : 'user';
                socket.emit('userDisconnected', [this.user.id, type]);
            }
            await apiClient.post('auth/logout', {
                    'socketId': localStorage.getItem('lastSocketId') || ""
                })
                .then(async (response) => {
                    this.user = null;
                    this.scopes = [];
                    this.role = null;
                    window.location.href = '/auth/login';
                    // await router.push({ name: 'login' });
                })
                .catch((error) => {
                    useAppHelpers().deleteAllCookies('XSRF-TOKEN');
                });
            if(this.isLoggedIn){
                this.user = null;
                this.scopes = [];
                this.role = null;
                window.location.href = '/auth/login';
            }

        },
        employerHasScope(scopes: string | Array<string> | undefined){
            return this.userHasScope(scopes,'any',true);
        },
        userHasScope(scopes: string | Array<string> | undefined, condition: string | undefined = 'any', managerPermissions = false) {
            let hasScope = false;
            if (condition == undefined) {
                condition = "any";
            }
            if (scopes == undefined || scopes.length == 0) {
                hasScope = true;
                return hasScope;
            }

            if (typeof scopes == 'string') {
                scopes = [scopes];
            }
            let permissions = managerPermissions? this.managerPermission : this.scopes ;

            if (scopes != undefined) {
                if (scopes) {
                    scopes.every((scope) => {
                        if (permissions.includes('*')) {
                            hasScope = true;
                            return false;
                        }

                        if (permissions.includes(scope)) {
                            hasScope = true;
                            if (condition == "any") {
                                return false;
                            }
                            return true;
                        }
                        else {
                            if (condition == "all") {
                                hasScope = false;
                                return false;
                            }
                            return true;
                        }
                        return true;
                    });
                }
            }
            return hasScope;
        },
        userHasRole(role: string | Array<string>){
            if(role == undefined){
                return true;
            }
            if(this.role){
                let roleAccess = true;

                if(typeof role == 'string'){
                    if(this.role == 'admin' || this.role == 'manager'){
                        roleAccess = role == 'manager';
                    }
                    else{
                        roleAccess = this.role == role
                    }
                }
                else{
                    if(!role.includes(this.role)){
                        roleAccess = false;
                    }
                }

                return roleAccess
            }
            return false;
        },
        userHasId(ids: string | Array<string | number> | undefined | number) {
            let hasId = false;
            if (ids == undefined || ((typeof ids == 'string' || typeof ids == 'object') && ids.length == 0) || ids == 0) {
                hasId = true;
                return hasId;
            }

            if (typeof ids == 'string' || typeof ids == 'number') {
                ids = [ids];
            }

            if (ids != undefined && ids) {
                ids.every((id) => {
                    if (this.user && this.user.id == id) {
                        hasId = true;
                        return false;
                    }
                    return true;
                });
            }
            return hasId;
        },
        async canAccessRoute(route) {
            let hasRole = this.userHasRole(route.meta.type);
            let hasScope = this.userHasScope(route.meta.scope, route.meta.scope_condition);
            let hasId = this.userHasId(route.meta.user_ids);

            if (!route.meta.authRequired && route.meta.isAuthLayout && !this.isLoggedIn) {
                return true;
            }
            else if (!route.meta.authRequired && !route.meta.isAuthLayout) {
                return true;
            }
            else if (route.meta.authRequired && hasRole && hasScope && hasId && this.isLoggedIn) {
                return true;
            }
            return false;
        }
    }
});