import { useAuthStore } from "@/stores/auth-store";
import { useToastManager } from "@/composables/useToastManager";
import router from '@/router';
import { TYPE, useToast } from "vue-toastification";
import { useAppHelpers } from "@/composables/useAppHelpers";
import { useHelperStore } from "@/stores/helper-store";

export function useHttpErrorManager() {

    const toast = useToast();

    const handleError = async (error, displayToast = false) => {
        let errors = {};

        let message = error.message;
        let error_message = "";
        let err_404 = false;

        if(error.response.status == 444){
            toast.clear();
                useToastManager().display(error.response.data.message, "error" as TYPE);
            return {};
        }

        if (error.response !== undefined && error.response.status !== undefined && error.response.status === 422) {
            displayToast = false;
            let error_set = error.response.data.errors;
            if(error_set){
                error_set = Object.entries(error_set);
                error_set.forEach((errorItem) => {
                    errors[errorItem[0]] = errorItem[1][0];
                });
            }
            error_message = error.response.data.message;
        }
        else if (error.response !== undefined && error.response.status !== undefined && error.response.status === 419) {
            toast.clear();
            error_message = error.response.data.message;
            useAppHelpers().deleteAllCookies('XSRF-TOKEN');
            window.location.reload();
        }
        else if (error.response !== undefined && error.response.status !== undefined && error.response.status === 404) {
            toast.clear();
            error_message = error.response.data.message;
            err_404 = true;
        }
        else if (error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
            toast.clear();
            error_message = error.response.data.message;
            if (useAuthStore().isLoggedIn) {
                useAuthStore().processLogout();
            }
        }
        else if (error.response !== undefined && error.response.status !== undefined && error.response.data !== undefined) {
            error_message = error.response.data.message;
        }

        if (error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined && error.response.data.title !== undefined) {
            error_message = error.response.data.message;
        }

        if (error_message != undefined || error_message != null || error_message != "") {
            errors['err_message'] = error_message;
        }

        if (message != undefined || message != null || message != "") {
            errors['alt_message'] = message;
        }

        if(displayToast){
            let temp_message = error_message;
            if(temp_message.trim() == ''){
                temp_message = message;
            }
            useToastManager().display(temp_message, "error" as TYPE);
        }

        if(err_404){
            useHelperStore().change404State(true);
        }

        return errors;
    }

    return { handleError };
}

