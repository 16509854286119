import { Message, MessageStoreObject } from "@/utils/types";
import { defineStore } from "pinia";

export const useMessageStore = defineStore("messageStore", {
    state: () => ({
        messages: {} as { [key: string | number]: MessageStoreObject },
        locks: {} as { [key: string | number]: boolean },
        fetchTokens: {} as { [key: string | number]: string | number},
        lastContactId: 0,
    }),
    actions: {
        setToken(contactId: string | number, token: string | number) {
            this.fetchTokens[contactId] = token;
        },
        async setMessages(
            contactId: string | number,
            messages: Array<Message>,
            allLoaded: boolean,
            fetchToken: string | number
        ) {

            while (this.locks[contactId]) {
                await new Promise((resolve) => setTimeout(resolve, 500));
            }

            this.locks[contactId] = true;

            try {
                if (this.fetchTokens[contactId] !== fetchToken) {
                    this.locks[contactId] = false;
                    return;
                }

                if (!this.messages.hasOwnProperty(contactId)) {
                    this.messages[contactId] = {
                        messages: [],
                        allLoaded: false,
                    };
                }
                const currentMessages = this.messages[contactId].messages;
                const currentMessageIds = new Set(
                    currentMessages.map((message) => message.id)
                );
                messages.forEach((message) => {
                    if (!currentMessageIds.has(message.id)) {
                        currentMessages.push(message);
                    }
                });

                this.messages[contactId].allLoaded = allLoaded;
            } finally {
                this.locks[contactId] = false;
                if (this.fetchTokens[contactId] === fetchToken) {
                    delete this.fetchTokens[contactId];
                }
            }
        },
        async addMessage(contactId: string | number, message: Message) {
            while (this.locks[contactId]) {
                await new Promise(resolve => setTimeout(resolve, 500));
            }
            this.locks[contactId] = true;
            try {
                if (!this.messages[contactId]) {
                    this.messages[contactId] = { messages: [], allLoaded: false };
                }
                const exists = this.messages[contactId].messages.some(m => m.id === message.id);
                if (!exists) {
                    this.messages[contactId].messages.push(message);
                }
            } finally {
                this.locks[contactId] = false;
            }
        },
        async updateMessage(contactId: string | number, updatedMessage: Message) {
            while (this.locks[contactId]) {
                await new Promise(resolve => setTimeout(resolve, 500));
            }
            this.locks[contactId] = true;

            try {
                if (!this.messages[contactId]) {
                    this.messages[contactId] = { messages: [], allLoaded: false };
                }
                const index = this.messages[contactId].messages.findIndex(m => m.id === updatedMessage.id);
                if (index !== -1) {
                    this.messages[contactId].messages.splice(index, 1, updatedMessage);
                } else {
                    this.messages[contactId].messages.push(updatedMessage);
                }
            } finally {
                this.locks[contactId] = false;
            }
        },
        deleteMessage(contactId: string | number, messageId: number) {
            if (this.messages.hasOwnProperty(contactId)) {
                this.messages[contactId].messages = this.messages[
                    contactId
                ].messages.filter((message) => message.id !== messageId);
            }
        },
        setAllLoaded(contactId: string | number, allLoaded: boolean) {
            if (this.messages.hasOwnProperty(contactId)) {
                this.messages[contactId].allLoaded = allLoaded;
            }
        },
        setAllRead(contactId: string | number) {
            if (this.messages.hasOwnProperty(contactId)) {
                const messages = this.messages[contactId].messages;

                for (let i = messages.length - 1; i >= 0; i--) {
                    if (messages[i].read === 0) {
                        messages[i].read = 1;
                    } else {
                        break;
                    }
                }
            }
        },
        initLocks(contactId: number) {
            if(!this.locks.hasOwnProperty(contactId)) {
                this.locks[contactId] = false;
            }
        },
    },
});
